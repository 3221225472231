@tailwind base;
@tailwind components;
@tailwind utilities;

/* hide scrollbar but allow scrolling */
body {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
}

body::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}

@media only screen and (min-width: 1124px) {
    .triangle {
        -webkit-clip-path: polygon(100% 0, 0 0, 100% 100%);
        clip-path: polygon(100% 0, 0 0, 100% 120%);
    }

    .over {
        margin-top: -13rem;
    }

    .border-radius-bello {
        border-radius: 60% 40% 40% 20% / 70% 50% 30% 25%;
    }
}

textarea {
    resize: none;
}

@layer utilities {
    .square {
        aspect-ratio: 1 / 1;
    }
}






